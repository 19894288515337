import React, { FC, ReactNode, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Heading } from "../heading/Heading";
import { InternalLink } from "../link/InternalLink";
import { Spinner } from "../spinner/Spinner";
import { Device } from "../../helpers/screenSizes";
import { fontWeights, Product } from "@pepdirect/shared/types";
import { colors } from "../../styles/variables";

// Only show in stock items...
// unless there are only out of stock items, then show those...
// unless there are no items, then return []
export const filterItems = (items: Product[]): Product[] => {
  const availableItems = (items || []).filter((item) => {
    return item && !item.isOutOfStock && item.inventoryQty;
  });

  if (availableItems.length) return availableItems;
  if (items) return items;
  return [];
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Styled404 = styled.div`
  width: calc(100vw - 160px);
  max-width: 1200px;
  margin: 70px 0 120px;

  @media ${Device.mobile} {
    width: calc(100vw - 40px);
  }
`;

const StyledHeadingH1 = styled(Heading)`
  color: ${({ theme }) => theme.color.text};
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const Text404 = styled.div`
  color: ${colors.lightestGray};
`;

const StyledHeadingH2 = styled(Heading)`
  color: ${({ theme }) => theme.color.text};
  margin-bottom: 60px;
  font-weight: ${fontWeights["400"]};

  @media ${Device.mobile} {
    margin-bottom: 40px;
  }
`;

const StyledHeadingH3 = styled(Heading)`
  color: ${({ theme }) => theme.color.text};
  margin-bottom: 60px;

  @media ${Device.mobile} {
    margin-bottom: 40px;
  }
`;

const StyledSpinner = styled(Spinner)`
  margin-top: 200px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 220px));
  justify-content: space-around;
  gap: 70px 30px;

  @media ${Device.mobile} {
    grid-template-columns: 1fr;
    justify-items: center;
    row-gap: 14px;

    article {
      width: 332px;
    }
  }
`;

interface Custom404Props {
  loading: boolean;
  children: ReactNode;
}

export const Custom404: FC<Custom404Props> = ({ loading, children }) => {
  const theme = useContext(ThemeContext);

  return (
    <Wrapper>
      <Styled404>
        <StyledHeadingH1 level="h1" size="xl">
          <div>Sorry, that page can&apos;t be found</div>
          <Text404>404</Text404>
        </StyledHeadingH1>
        <StyledHeadingH2 level="h2" size="m">
          It could be a typo, or it could be missing.{" "}
          <InternalLink href="/" color={theme.color.primary}>
            Return to the home page.
          </InternalLink>
        </StyledHeadingH2>
        {Array.isArray(children) && !!children.length && (
          <>
            <StyledHeadingH3 level="h3" size="l">
              Or, check out some featured products
            </StyledHeadingH3>
            {loading ? (
              <StyledSpinner dotSize="20px" />
            ) : (
              <FlexWrapper>
                <Grid>{children}</Grid>
              </FlexWrapper>
            )}
          </>
        )}
      </Styled404>
    </Wrapper>
  );
};
