// COLLECTION PAGE SPECIAL BREAKPOINT WIDTHS
const Size = {
  desktopMin: "1440px",
  laptopMax: "1439px",
  laptopMin: "1250px",
  tabletMax: "1249px",
  tabletMin: "1050px",
  mobileMax: "1049px",
  mobileMin: "700px",
  mobileSmMax: "699px",
  mobileSmMin: "400px",
  smallestMax: "399px",
};

// COLLECTION PAGE SPECIAL BREAKPOINTS
export const Device = {
  desktop: `(min-width: ${Size.desktopMin})`,
  laptop: `(min-width: ${Size.laptopMin}) and (max-width: ${Size.laptopMax})`,
  tablet: `(min-width: ${Size.tabletMin}) and (max-width: ${Size.tabletMax})`,
  mobile: `(min-width: ${Size.mobileMin}) and (max-width: ${Size.mobileMax})`,
  mobileSm: `(min-width: ${Size.mobileSmMin}) and (max-width: ${Size.mobileSmMax})`,
  smallest: `(max-width: ${Size.smallestMax})`,
  notSmallest: `(min-width: ${Size.mobileSmMin})`,
};

export const BundleTileGap = {
  desktop: 50,
  laptop: 50,
  tablet: 24,
  mobile: 48,
  mobileSm: 40,
};
