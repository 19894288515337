import React, { FC, useContext, useCallback } from "react";
import Image from "next/image";
import styled from "styled-components";
import { ZipCodeContext } from "context/zipCode";
import { CartContext } from "context/cart";
import { UserContext } from "context/user";
import { InternalLink } from "@pepdirect/ui/InternalLink";
import { BundleTileLayout } from "@pepdirect/ui/layout/BundleTileLayout";
import { AddToCartButtons } from "components/AddToCartButtons";
import { Price } from "components/Price";
import {
  logProductTileBuyButtonClickEvent,
  logProductClickedEvent,
} from "@pepdirect/helpers/analyticsLogger";
import { getCloudflareLoader } from "@pepdirect/helpers/cloudflareImages";
import { Product } from "@pepdirect/shared/types";
import { endpoints } from "config";
import { Device } from "@pepdirect/ui/Device";
import st from "./bundleTile.module.scss";

interface BundleTileProps {
  item: Product;
  collection?: string;
  isInRecommendedSection?: boolean;
}

const LinkAndImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ImageWrapper = styled.div`
  position: relative; /* Necessary for layout="fill" */
  width: 100%; /* Full width of the wrapper */
  height: 0; /* Set to 0 to allow padding-based aspect ratio */
  padding-top: 100%; /* 1:1 aspect ratio for a square */
`;

export const BundleTile: FC<BundleTileProps> = ({
  item,
  collection,
  isInRecommendedSection = false,
}) => {
  const { cart, updateCartItem } = useContext(CartContext);
  const { currentUserId } = useContext(UserContext);
  const { blockedItems } = useContext(ZipCodeContext);

  const {
    itemTitle,
    compareAtPrice,
    itemPrice,
    _id,
    isOutOfStock,
    itemSlug,
    itemImgUrl,
    inventoryQty,
    minOrderQty,
  } = item;

  const isItemUnavailable = useCallback(
    (id: string) => {
      return blockedItems.includes(id);
    },
    [blockedItems]
  );

  const cartItem = cart?.items?.find((item) => item.product.id === _id);
  const quantity = cartItem?.quantity || 0;
  const queryParams = new URLSearchParams();
  collection && queryParams.append("collection", collection);
  const internalUrl = `/product/${itemSlug}?${queryParams.toString()}`;

  const handleProductClick = () =>
    logProductClickedEvent(currentUserId, item as Product);

  return (
    <BundleTileLayout isInRecommendedSection={isInRecommendedSection}>
      <LinkAndImageWrapper>
        <InternalLink href={internalUrl} onClick={handleProductClick}>
          <ImageWrapper>
            <Image
              className={st.image}
              src={itemImgUrl || ""}
              loader={getCloudflareLoader(endpoints.productsImgCdn)}
              alt={itemTitle || ""}
              fill
              sizes={`${Device.mobile} 100vw, ${Device.tablet} 50vw, ${Device.laptop} 25vw`}
              style={{
                objectFit: "contain",
              }}
            />
          </ImageWrapper>
        </InternalLink>
      </LinkAndImageWrapper>
      <InternalLink href={internalUrl} onClick={handleProductClick}>
        <h2 className={st.title}>{itemTitle}</h2>
      </InternalLink>
      {/* TODO: add library with truncation if needed */}
      <Price
        original={compareAtPrice}
        current={itemPrice}
        isInCents={!isInRecommendedSection}
      />
      <div className={st.add}>
        <AddToCartButtons
          quantity={quantity}
          onChange={(quantity: number) => {
            logProductTileBuyButtonClickEvent(
              currentUserId,
              item as Product,
              quantity
            );
            return updateCartItem({
              productId: _id,
              quantity,
              subscriptionIntervalInDays:
                cartItem?.subscriptionIntervalInDays || null,
            });
          }}
          outOfStock={!!isOutOfStock}
          unavailable={isItemUnavailable(_id)}
          inventoryQty={inventoryQty || 0}
          minOrderQty={minOrderQty || 0}
        />
      </div>
    </BundleTileLayout>
  );
};
