import React, { useContext, useState } from "react";
import { AppContext } from "context/app";
import { Button } from "@pepdirect/ui/button";
import { cart } from "config";
import st from "./addToCartButtons.module.scss";

type State = "EMPTY" | "DISABLED" | "ACTIVE";

export function AddToCartButtons({
  quantity,
  onChange,
  outOfStock = false,
  unavailable = false,
  inventoryQty = Infinity,
  minOrderQty,
}: {
  quantity: number;
  onChange: (qty: number) => Promise<void>;
  outOfStock?: boolean;
  unavailable?: boolean;
  inventoryQty?: number;
  minOrderQty?: number;
}): JSX.Element {
  const [loading, setLoading] = useState<string | boolean>(false);
  const [error, setError] = useState("");
  const { tenant } = useContext(AppContext);
  const { maxCartQty: maxCartQtyDefault } = cart;
  const maxCartQty =
    tenant?.shoppingBagConfig?.maxAmountForSelect || maxCartQtyDefault;

  const max = Math.min(maxCartQty, inventoryQty);
  const min = minOrderQty || 1;
  const isMax = max === quantity;
  const isMin = min === quantity;

  /* TODO: should we move it to the parent?*/
  const onButtonPress = async (action: "increase" | "decrease") => {
    // TODO: move to separate function?
    let qty = quantity;
    if (action === "increase") {
      if (quantity === 0) {
        qty = min;
      } else if (!isMax) {
        qty++;
      }
    } else if (action === "decrease") {
      if (isMin) {
        qty = 0;
      } else {
        qty--;
      }
    }

    try {
      setLoading(action || true);
      await onChange(qty);
      setError("");
    } catch (error) {
      setError("Could not add product");
    } finally {
      setLoading(false);
    }
  };

  let state: State = "ACTIVE";
  if (quantity === 0) state = "EMPTY";
  if (outOfStock || !inventoryQty || unavailable) state = "DISABLED";

  return (
    <div className={st.outerWrapper}>
      <div>
        {state === "EMPTY" && (
          <Button
            type="secondary"
            fullWidth
            onClick={() => onButtonPress("increase")}
            loading={!!loading}
          >
            Add To Cart
          </Button>
        )}
        {state === "DISABLED" && (
          <Button disabled fullWidth loading={!!loading}>
            {unavailable ? "Unavailable" : "Out of stock"}
          </Button>
        )}
        {state === "ACTIVE" && (
          <div className={st.wrapper}>
            <Button
              onClick={() => onButtonPress("decrease")}
              type="secondary"
              position="left"
              loading={loading === "decrease"}
              small
            >
              -
            </Button>
            <div className={st.center}>{quantity}</div>
            <Button
              onClick={() => onButtonPress("increase")}
              type="secondary"
              position="right"
              loading={loading === "increase"}
              disabled={isMax}
              small
            >
              +
            </Button>
          </div>
        )}
      </div>
      {error && <span className={st.error}>{error}</span>}
    </div>
  );
}
