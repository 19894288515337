import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { Device } from "../helpers/screenSizes";
import { Device as DeviceCollection, BundleTileGap } from "./sizes";

const BundleTileWidth = {
  desktop: 275,
  laptop: 225,
  tablet: 225,
  mobile: 175,
  mobileSm: 160,
};

const StyledBundleTileLayout = styled.article<BundleTileLayoutProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignLeft }) => (alignLeft ? "flex-start" : "center")};
  margin-bottom: 70px;

  h2 {
    @media ${DeviceCollection.notSmallest} {
      margin: 20px 0 10px;
    }
  }

  ${({ isInRecommendedSection }) =>
    isInRecommendedSection &&
    `
    margin: 0;
    width: 160px;
    @media ${Device.notMobile} {
      width: 270px;
    }
  `};

  @media ${DeviceCollection.desktop} {
    margin: 0 ${BundleTileGap.desktop / 2}px 70px;
    width: ${BundleTileWidth.desktop}px;
  }

  @media ${DeviceCollection.laptop} {
    margin: 0 ${BundleTileGap.laptop / 2}px 70px;
    width: ${BundleTileWidth.laptop}px;
  }

  @media ${DeviceCollection.tablet} {
    margin: 0 ${BundleTileGap.tablet / 2}px 70px;
    width: ${BundleTileWidth.tablet}px;
  }

  @media ${DeviceCollection.mobile} {
    margin: 0 ${BundleTileGap.mobile / 2}px 70px;
    width: ${BundleTileWidth.mobile}px;
  }

  @media ${DeviceCollection.mobileSm} {
    margin: 0 ${BundleTileGap.mobileSm / 2}px 70px;
    width: ${BundleTileWidth.mobileSm}px;
  }

  @media ${DeviceCollection.smallest} {
    margin: 0 ${BundleTileGap.mobile / 2}px 70px;
    width: ${BundleTileWidth.mobile}px;
  }

  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

interface BundleTileLayoutProps {
  children: ReactNode;
  isInRecommendedSection?: boolean;
  alignLeft?: boolean;
}

export const BundleTileLayout: FC<BundleTileLayoutProps> = ({
  children,
  isInRecommendedSection,
  alignLeft,
}) => (
  <StyledBundleTileLayout
    isInRecommendedSection={isInRecommendedSection}
    alignLeft={alignLeft}
  >
    {children}
  </StyledBundleTileLayout>
);
