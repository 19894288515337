import React from "react";
import { formatDollars, convertFromCentsToDollars } from "helpers/currency";
import st from "./price.module.scss";

export function Price({
  original = 0,
  current,
  isInCents = false,
}: {
  original?: number | null;
  current?: number | null;
  isInCents?: boolean;
}): JSX.Element {
  const originalFormatted = isInCents
    ? convertFromCentsToDollars(original || 0)
    : formatDollars(original || 0);
  const newFormatted = isInCents
    ? convertFromCentsToDollars(current || 0)
    : formatDollars(current || 0);

  const displayBothPrices = !!original && originalFormatted !== newFormatted;

  return (
    <div className={st.wrapper}>
      {displayBothPrices && (
        <>
          <span className={st.original}>{originalFormatted}</span>{" "}
        </>
      )}
      <span className={st.new}>{newFormatted}</span>
    </div>
  );
}
