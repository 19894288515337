import React from "react";
import { Custom404, filterItems } from "@pepdirect/ui/Custom404";
import { BundleTile } from "components/BundleTile";
import { useStoryBySlugQuery } from "services/graphql/generated";
import { Product } from "@pepdirect/shared/types";
import st from "./404.module.scss";

export default function Custom404Page(): JSX.Element {
  const { loading, data } = useStoryBySlugQuery({
    variables: {
      slug: "bestsellers",
    },
  });

  return (
    <div className={st.container}>
      <Custom404 loading={loading}>
        {filterItems(data?.story?.items as Product[]).map(
          (item) =>
            item && (
              <BundleTile key={item._id} item={item} collection="Bestsellers" />
            )
        )}
      </Custom404>
    </div>
  );
}
